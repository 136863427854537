import React, {useEffect} from "react";
import { Row, Col } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import FusionExerciseAirTab from '../../components/FusionExerciseAirTab/FusionExerciseAirTab';
import FusionMasterSlider from '../../components/FusionMasterSlider/FusionMasterSlider';
import Footer from '../../components/Footer/Footer';
import playerIcon from '../../assets/images/personHealth.png';
import ClusterIcons from 'shared/ui/cluster-icons';
import Calender from '../../components/calender';
import './FusionExerciseAir.scss';
import HealthInfoWrapper from '../../components/health-info-wrapper'
import FooterBanner from '../../components/footer-banner';

const FusionExercise = () => {
    

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="exercise-air-wrapper">
            <Header />
            <div className="Fusion-head">
                <div className="Fusion-head_details">
                    <div className="breadCrumbs">Advancement exercises - Health</div>
                    <div className="title">Fusion Exercise</div>
                    <div className="subtitle">Our wish for you – greater happiness and <br />health span</div>
                    <div className="data">We teach that you are a powerful creator and given the right Techniques you can create your own joy and magnificence. The Mind X Body fusion style classes weave in a philosophy of ‘Genuine Increase’; that for your healing, greater growth and advancement, your good intent and actions towards others must also rise in good measure.
                    <br /><br />This is an understanding that while you may need to primarily look after your own needs and become stronger, fitter and healthier you can further catalyse that process by recognising the health interconnection of extending goodwill. As you progress through the Mind X Body classes from Air to Diamond you will gradually shed the emotional ill health and physical stagnation you once had and experience the full vitality of your being, in doing so you will build extreme capacity; physically, mentally, emotionally and spiritually.</div>
                </div>
                <div id='right-img'>
                    <img src={playerIcon} alt='playerIcon' />
                </div>
                <ClusterIcons />
            </div>
            <HealthInfoWrapper dotGlobal={1}/>
            <FusionExerciseAirTab />

            <Row className="m-5">
                <Col sm={12} md={12}>
                    <div className="slider-secs">
                        <h1>Our Fusion Facilitators and Masters</h1>
                        <FusionMasterSlider></FusionMasterSlider>
                    </div>

                </Col>
            </Row>
            <Calender />
            <FooterBanner />
            <Footer />
            <div id='weight-shift-plan'>Weight Shifter Exercise Plan</div>
        </div>
    );
}

export default FusionExercise;