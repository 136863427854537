import React from "react";
import { Row, Col } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import HomeWorkoutTabs from '../../components/home-workout-tabs';
import FusionMasterSlider from '../../components/FusionMasterSlider/FusionMasterSlider';
import playerIcon from '../../assets/images/personHealth.png';
import ClusterIcons from 'shared/ui/cluster-icons';
import Calender from '../../components/calender';
import './index.scss';
import HealthInfoWrapper from '../../components/health-info-wrapper'
import FooterBanner from '../../components/footer-banner';

const HomeWorkouts = () => {
    return (
        <div className="exercise-air-wrapper">
            <Header />
            <div className="Banner-head">
                <div className="Banner-head_details">
                    <div className="breadCrumbs">Advancement exercises - Health</div>
                    <div className="title">Home and outdoor workouts</div>
                    <div className="data">Become resourceful, innovative and empowered as you gain greater strength, fitness and flexibility with the Sanctuary home workout program. Learn how to work out anywhere with this comprehensive range of; Stretches, Callisthenic, Isometric and High Intensity Interval training (H.I.I.T) sessions.
                    <br /><br />Stretching sequences; both dynamic and static for safely warming up and down. Callisthenic for fitness, and all-round strength and tone. Isometrics for incredible strength building and power. And H.I.I.T for excellent fitness, cardio, fast loss, heart health, and anti-aging benefits.</div>
                </div>
                <div id='right-img'>
                    <img src={playerIcon} alt='playerIcon' />
                </div>
                <ClusterIcons />
            </div>
            <HealthInfoWrapper dotGlobal={1}/>
            <HomeWorkoutTabs />

            <Row className="m-5">
                <Col sm={12} md={12}>
                    <div className="slider-secs">
                        <h1>Our Fusion Facilitators and Masters</h1>
                        <FusionMasterSlider></FusionMasterSlider>
                    </div>

                </Col>
            </Row>
            <Calender />
            <FooterBanner />
            <Footer />
            <div id='weight-shift-plan'>Weight Shifter Exercise Plan</div>
        </div>
    );
}

export default HomeWorkouts;