import React from 'react';
import Slider from "react-slick";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useLocation } from 'react-router-dom';
import sigment1 from '../../assets/images/exercise_air_detail/sigment1.png';
import sigment2 from '../../assets/images/exercise_air_detail/sigment2.png';
import sigment3 from '../../assets/images/exercise_air_detail/sigment3.png';
import sigment4 from '../../assets/images/exercise_air_detail/sigment4.png';
import sigment5 from '../../assets/images/exercise_air_detail/sigment5.png';
import sigment6 from '../../assets/images/exercise_air_detail/sigment6.png';
import sigment7 from '../../assets/images/exercise_air_detail/sigment7.png';
import Aff1 from '../../assets/images/fusion-exercise-detail/aff1.png';
import Aff2 from '../../assets/images/fusion-exercise-detail/aff2.png';
import Aff3 from '../../assets/images/fusion-exercise-detail/aff3.png';
import Aff4 from '../../assets/images/fusion-exercise-detail/aff4.png';
import Aff5 from '../../assets/images/fusion-exercise-detail/aff5.png';

import Yoga2 from '../../assets/images/fusion-exercise-detail/yoga2.png';
import Yoga3 from '../../assets/images/fusion-exercise-detail/yoga3.png';
import Yoga4 from '../../assets/images/fusion-exercise-detail/yoga4.png';
import Yoga5 from '../../assets/images/fusion-exercise-detail/yoga5.png';
import Yoga6 from '../../assets/images/fusion-exercise-detail/yoga6.png';
import Save from '../../assets/images/fusion-exercise-detail/save.png';
import Basket from '../../assets/images/fusion-exercise-detail/basket.png';


import playerIcons from '../../assets/images/player-icon.png';
import './ExerciseDetailSigmentTab.scss';

const ExerciseDetailSigmentTab = () => {
  const { pathname } = useLocation();
  var sigCard = {
    autoplay: true,
    dots: false,
    infinite: true,
    autoplaySpeed: 1500,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 5,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <>
      <Tabs defaultActiveKey="second">
        <Tab eventKey="first" title="Select Ambiance">
          <Slider {...sigCard}>
            <div className="sig-card">
              <p>Okinawan Garden</p>
              <img alt="artifact missing" src={sigment1} />
            </div>

            <div className="sig-card">
              <p>Tropical Beach</p>
              <img alt="artifact missing" src={sigment2} />
            </div>
            <div className="sig-card">
              <p>New York loft</p>
              <img alt="artifact missing" src={sigment3} />
            </div>
            <div className="sig-card">
              <p>Warehouse</p>
              <img alt="artifact missing" src={sigment4} />
            </div>
            <div className="sig-card">
              <p>High Tech Studio</p>
              <img alt="artifact missing" src={sigment5} />
            </div>
            <div className="sig-card">
              <p>Forest Water Fall</p>
              <img alt="artifact missing" src={sigment6} />
            </div>
            <div className="sig-card">
              <p>Lake</p>
              <img alt="artifact missing" src={sigment7} />
            </div>
            <div className="sig-card">
              <p>High Tech Studio</p>
              <img alt="artifact missing" src={sigment5} />
            </div>
          </Slider>
        </Tab>
        {(pathname === '/fusion-exercise-detail2' || pathname === '/fusion-exercise-detail6') &&
          <Tab eventKey="second" title="Select Segments">
            <Slider {...sigCard}>
              <div className="sig-card">
                <img alt="artifact missing" src={Aff1} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
                <img alt="artifact missing" src={Aff2} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
                <img alt="artifact missing" src={Aff3} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
                <img alt="artifact missing" src={Aff4} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
                <img alt="artifact missing" src={Aff5} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
                <img alt="artifact missing" src={Aff3} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
            </Slider>
          </Tab>
        }
        {pathname === '/fusion-exercise-detail4' &&
          <Tab eventKey="second" title="Select Segments">
            <Slider {...sigCard}>
              <div className="sig-card">
                <div className='title-icon'><p>Deep meditative<br />
                  breathing</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Yoga2} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
                <div className='title-icon'><p>Forgiveness</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Yoga3} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
                <div className='title-icon'><p>Blessings</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Yoga4} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
                <div className='title-icon'><p>Gratitude</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Yoga5} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
                <div className='title-icon'><p>Love</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Yoga6} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
                <div className='title-icon'><p>Forgiveness</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Yoga3} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
            </Slider>
          </Tab>
        }
         {pathname === '/fusion-exercise-detail5' &&
          <Tab eventKey="second" title="Select Segments">
            <Slider {...sigCard}>
              <div className="sig-card">
              <div className='title-icon'><p>Vibrancy</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff1} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
              <div className='title-icon'><p>Sports Performance</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff2} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
              <div className='title-icon'><p>Healing</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff3} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
              <div className='title-icon'><p>Health Span</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff4} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
              <div className='title-icon'><p>Relaxation</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff5} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
              <div className='title-icon'><p>Healing</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff3} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
            </Slider>
          </Tab>
        }
             {pathname === '/fusion-exercise-detail7' &&
          <Tab eventKey="second" title="Select Segments">
            <Slider {...sigCard}>
              <div className="sig-card">
              <div className='title-icon'><p>Pray / faith / 
peace and 
tranquillity</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff1} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
              <div className='title-icon'><p>Spiritual / Ancient 
Wisdom / Mystery 
And Awe</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff2} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
              <div className='title-icon'><p>Science</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff3} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
              <div className='title-icon'><p>Life Style Healing /
Yoga / Alternative 
Medicine</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff4} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
              <div className='title-icon'><p>Personal 
Inspirational Stories</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff5} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
              <div className="sig-card">
              <div className='title-icon'><p>Science</p>
                  <div className='img-s-b'>
                    <img alt="artifact missing" src={Save} />
                    <img alt="artifact missing" src={Basket} />
                  </div>
                </div>
                <img alt="artifact missing" src={Aff3} />
                <img alt="artifact missing" className='playicon' src={playerIcons} />
              </div>
            </Slider>
          </Tab>
        }
      </Tabs>
    </>
  );
}

export default ExerciseDetailSigmentTab;