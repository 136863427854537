import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import blackDiamond from '../../assets/images/black-diamond.png';
import Yoga from '../../assets/images/exercise_air_detail/yoga.png';
import favIcon1 from '../../assets/images/fav_icon1.png';
import favIcon2 from '../../assets/images/fav_icon2.png';
import playerIcons from '../../assets/images/player-icon.png';
import Yoga1 from '../../assets/images/other-img-2.png';
import './ExerciseDetailVideo.scss';
import { StrapiVideo } from 'shared/ui/StrapiVideo';
import ExerciseDetailSigmentTab from '../ExerciseDetailSigmentTab/ExerciseDetailSigmentTab';
import nounVideo from '../../assets/images/exercise_air_detail/noun_Video.png';
import { Typography } from '@material-ui/core';
import { unstable_batchedUpdates } from 'react-dom';

const ExerciseDetailVideo = ({ data, bgImg = Yoga, intensity = 'light' }) => {
  const [dotGlobal, changeImg] = useState(1);
  const [intense, setIntensity] = useState(intensity)
  const [bg, setBg] = useState(bgImg);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // 0 === sequence, 1 == segment
  const [activeTab, setActiveTab] = useState(0);

  const segments = data?.data.attributes.videoSegment?.map(i => ({ url: i.video.data.attributes.url, title: i.title }));
  const sequences = data?.data.attributes.videoSequence?.map(i => ({ url: i.video.data.attributes.url, title: i.title }));

  const handleSegmentChange = (tabCode, segmentIdx) => {
    console.log(tabCode, segmentIdx, 'tabCode, segmentIdx')
    unstable_batchedUpdates(() => {
      setActiveTab(tabCode);
      setCurrentVideoIndex(segmentIdx);
    });
  };

  const getList = () => activeTab === 0 ? sequences : segments;

  const getVideo = () => getList()[currentVideoIndex];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    dotsClass: "slick-dots slick-thumb",
    slidesToScroll: 1,
    maxWidth: 50,
    // customPaging: function (i) {
    //   return (
    //     <a>
    //       <div className="tb-info" >
    //         <img alt="artifact missing" src={`${'Cluster-'}${i + 1}.png`} onClick={() => changeImg(i + 1)} />
    //       </div>
    //     </a>
    //   );
    // },
  };



  return (
    <>
      <Row>
        <Col sm={12} md={12}>
          {/* <Slider {...settings}> */}
          <div>
            <div className="vedio-sec" >
              {getList() && getList().length > 0 &&
                <div className="vedio-con">
                  <div className="vedios">
                    {getList().length > 0 &&
                      <StrapiVideo segment={getVideo().url} />}
                  </div>
                  <Typography variant="h5">{getVideo().title}</Typography>
                  <div className="vedios-pagi">
                    <p>On Demand {data?.data?.attributes.title} classes:</p>
                    <div style={{ display: 'flex' }}>
                      {getList()?.map((i, index) => {
                        return (
                          <span key={index} onClick={() => setCurrentVideoIndex(index)}>{index + 1}</span>
                        )
                      })}
                    </div>
                  </div>
                </div>
              }
              <div className="profile-info">
                <div className="info-con">
                  <div className="profile-img">
                    <img alt="artifact missing" src={blackDiamond} />
                    <h3>Exp: 35 years</h3>
                  </div>
                  <div className="profile-text">
                    <label>Trainer :</label><span>John Doe</span>
                    <p>Kim Larking, creator of Mind X Body Fusion Exercise, author of the book Health Psychology, 6th degree Kiaido Ryu black belt, aikido and yoga enthusiast, anti-aging and health span educator.</p>
                  </div>
                </div>
                <div className="profile-wrap">
                  <Row>
                    <Col sm={12} md={12}>
                      <div className="profile-health">
                        <h2>My Health Info</h2>
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="health">
                        <p>Age:</p>
                        <p>18</p>
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="health">
                        <p>Gender:</p>
                        <p>Female</p>
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="health">
                        <p>Weight:</p>
                        <p>54kg</p>
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="health">
                        <p>Height:</p>
                        <p>168cm</p>
                      </div>

                    </Col>
                    <div className="text-cmp">
                      <p> {">>"} Complete Your Health Info</p>
                    </div>
                    <Col sm={12} md={12}>
                      <div className="profile-health">
                        <h2>Workout Info</h2>
                        <div className="shealth">
                          <p>Workout Duration:</p>
                          <span>60 Minutes</span>
                        </div>
                      </div>
                      <div className="work-health">
                        <p>Intent intensity:</p>
                        <div className="btn-health">
                          <button className={`${intense === 'light' ? 'hactive' : ''}`} onClick={() => setIntensity('light')}>Light</button>
                          <button className={`${intense === 'medium' ? 'hactive' : ''}`} onClick={() => setIntensity('medium')}>Medium</button>
                          <button className={`${intense === 'maximum' ? 'hactive' : ''}`} onClick={() => setIntensity('maximum')}>Maximum</button>
                        </div>
                      </div>
                      <div className="max-health">
                        <p>Maximum Expected Calorie Burn:</p>
                        <p>300kcl</p>
                        <img alt="artifact missing" src={favIcon1} />
                        <img alt="artifact missing" src={favIcon2} />
                      </div>
                      <div className="max-health mt-2">
                        <a href="">Pre Workout Life Force Food</a>
                        <a href="">Post Work Life Force Food</a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
              <div className="vedio-sec">
                <div className="vedio-con">
                  <div className="vedios">
                    <img alt="artifact missing" src={Yoga1} />
                    <img alt="artifact missing" src={playerIcons} />
                  </div>
                  <div className="vedios-pagi">
                    <p>On Demand air classes:</p>
                    <div style={{ display: 'flex' }}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                        return (
                          <span onClick={() => i !== 1 ? setBg(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="profile-info">
                  <div className="info-con">
                    <div className="profile-img">
                      <img alt="artifact missing" src={blackDiamond} />
                      <h3>Exp: 35 years</h3>
                    </div>
                    <div className="profile-text">
                      <label>Trainer :</label><span>John</span>
                      <p>Kim Larking, creator of Mind X Body Fusion Exercise, author of the book Health Psychology, 6th degree Kiaido Ryu black belt, aikido and yoga enthusiast, anti-aging and health span educator.</p>
                    </div>
                  </div>
                  <div className="profile-wrap">
                    <Row>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>My Health Info</h2>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Age:</p>
                          <p>18</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Gender:</p>
                          <p>Female</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Weight:</p>
                          <p>54kg</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Height:</p>
                          <p>168cm</p>
                        </div>

                      </Col>
                      <div className="text-cmp">
                        <p> {">>"} Complete Your Health Info</p>
                      </div>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>Workout Info</h2>
                          <div className="shealth">
                            <p>Workout Duration:</p>
                            <span>60 Minutes</span>
                          </div>
                        </div>
                        <div className="work-health">
                          <p>Intent intensity:</p>
                          <div className="btn-health">
                            <button className={`${intense === 'light' ? 'hactive' : ''}`} onClick={() => setIntensity('light')}>Light</button>
                            <button className={`${intense === 'medium' ? 'hactive' : ''}`} onClick={() => setIntensity('medium')}>Medium</button>
                            <button className={`${intense === 'maximum' ? 'hactive' : ''}`} onClick={() => setIntensity('maximum')}>Maximum</button>
                          </div>
                        </div>
                        <div className="max-health">
                          <p>Maximum Expected Calorie Burn:</p>
                          <p>300kcl</p>
                          <img alt="artifact missing" src={favIcon1} />
                          <img alt="artifact missing" src={favIcon2} />
                        </div>
                        <div className="max-health">
                          <a href="">Pre Workout Life Force Food</a>
                          <a href="">Post Work Life Force Food</a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="vedio-sec">
                <div className="vedio-con">
                  <div className="vedios">
                    <img alt="artifact missing" src={Yoga} />
                    <img alt="artifact missing" src={playerIcons} />
                  </div>
                  <div className="vedios-pagi">
                    <p>On Demand air classes:</p>
                    <div style={{ display: 'flex' }}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                        return (
                          <span onClick={() => i !== 1 ? setBg(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="profile-info">
                  <div className="info-con">
                    <div className="profile-img">
                      <img alt="artifact missing" src={blackDiamond} />
                      <h3>Exp: 35 years</h3>
                    </div>
                    <div className="profile-text">
                      <label>Trainer :</label><span>Kim Larking</span>
                      <p>Kim Larking, creator of Mind X Body Fusion Exercise, author of the book Health Psychology, 6th degree Kiaido Ryu black belt, aikido and yoga enthusiast, anti-aging and health span educator.</p>
                    </div>
                  </div>
                  <div className="profile-wrap">
                    <Row>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>My Health Info</h2>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Age:</p>
                          <p>18</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Gender:</p>
                          <p>Female</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Weight:</p>
                          <p>54kg</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Height:</p>
                          <p>168cm</p>
                        </div>

                      </Col>
                      <div className="text-cmp">
                        <p> {">>"} Complete Your Health Info</p>
                      </div>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>Workout Info</h2>
                          <div className="shealth">
                            <p>Workout Duration:</p>
                            <span>60 Minutes</span>
                          </div>
                        </div>
                        <div className="work-health">
                          <p>Intent intensity:</p>
                          <div className="btn-health">
                            <button className={`${intense === 'light' ? 'hactive' : ''}`} onClick={() => setIntensity('light')}>Light</button>
                            <button className={`${intense === 'medium' ? 'hactive' : ''}`} onClick={() => setIntensity('medium')}>Medium</button>
                            <button className={`${intense === 'maximum' ? 'hactive' : ''}`} onClick={() => setIntensity('maximum')}>Maximum</button>
                          </div>
                        </div>
                        <div className="max-health">
                          <p>Maximum Expected Calorie Burn:</p>
                          <p>300kcl</p>
                          <img alt="artifact missing" src={favIcon1} />
                          <img alt="artifact missing" src={favIcon2} />
                        </div>
                        <div className="max-health">
                          <a href="">Pre Workout Life Force Food</a>
                          <a href="">Post Work Life Force Food</a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="vedio-sec">
                <div className="vedio-con">
                  <div className="vedios">
                    <img alt="artifact missing" src={Yoga1} />
                    <img alt="artifact missing" src={playerIcons} />
                  </div>
                  <div className="vedios-pagi">
                    <p>On Demand air classes:</p>
                    <div style={{ display: 'flex' }}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                        return (
                          <span onClick={() => i !== 1 ? setBg(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="profile-info">
                  <div className="info-con">
                    <div className="profile-img">
                      <img alt="artifact missing" src={blackDiamond} />
                      <h3>Exp: 35 years</h3>
                    </div>
                    <div className="profile-text">
                      <label>Trainer :</label><span>Larking</span>
                      <p>Kim Larking, creator of Mind X Body Fusion Exercise, author of the book Health Psychology, 6th degree Kiaido Ryu black belt, aikido and yoga enthusiast, anti-aging and health span educator.</p>
                    </div>
                  </div>
                  <div className="profile-wrap">
                    <Row>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>My Health Info</h2>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Age:</p>
                          <p>18</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Gender:</p>
                          <p>Female</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Weight:</p>
                          <p>54kg</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Height:</p>
                          <p>168cm</p>
                        </div>

                      </Col>
                      <div className="text-cmp">
                        <p> {">>"} Complete Your Health Info</p>
                      </div>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>Workout Info</h2>
                          <div className="shealth">
                            <p>Workout Duration:</p>
                            <span>60 Minutes</span>
                          </div>
                        </div>
                        <div className="work-health">
                          <p>Intent intensity:</p>
                          <div className="btn-health">
                            <button className={`${intense === 'light' ? 'hactive' : ''}`} onClick={() => setIntensity('light')}>Light</button>
                            <button className={`${intense === 'medium' ? 'hactive' : ''}`} onClick={() => setIntensity('medium')}>Medium</button>
                            <button className={`${intense === 'maximum' ? 'hactive' : ''}`} onClick={() => setIntensity('maximum')}>Maximum</button>
                          </div>
                        </div>
                        <div className="max-health">
                          <p>Maximum Expected Calorie Burn:</p>
                          <p>300kcl</p>
                          <img alt="artifact missing" src={favIcon1} />
                          <img alt="artifact missing" src={favIcon2} />
                        </div>
                        <div className="max-health">
                          <a href="">Pre Workout Life Force Food</a>
                          <a href="">Post Work Life Force Food</a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div> */}
          {/* </Slider> */}
        </Col>

        <Col sm={12} md={12}>
          <div className="sigment-img">
            <img alt="not found" src={nounVideo} className="sigment-img-right" />
            <ExerciseDetailSigmentTab segments={segments} sequences={sequences}
              onSegmentChange={handleSegmentChange}
            />
          </div>
        </Col>
      </Row >
    </>
  );
}

export default ExerciseDetailVideo;