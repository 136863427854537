import React, {useEffect} from 'react';
import Header from '../../components/Header/Header';
import ClusterIcons from 'shared/ui/cluster-icons';
import ExerciseDetailSigmentTab from '../../components/ExerciseDetailSigmentTab/ExerciseDetailSigmentTab';
import ExerciseDetailVideo from '../../components/ExerciseDetailVideo/ExerciseDetailVideo';

import { Row, Col } from 'react-bootstrap'; 
import Slider from "react-slick";
import playerIcon from '../../assets/images/personHealth.png';
import bannerIng from '../../assets/images/fu-exercise2.png';
import nounVideo from '../../assets/images/exercise_air_detail/noun_Video.png';
import editIcon from '../../assets/images/edit-icon.png';
import pic1 from '../../assets/images/pic1.png'
import pic2 from '../../assets/images/pic2.png'
import pic3 from '../../assets/images/pic3.png'
import startCom from '../../assets/images/start-com.png'
import endCom from '../../assets/images/end-com.png'
import otherImg1 from '../../assets/images/exercise_air_detail/other-img-1.png'
import otherImg2 from '../../assets/images/exercise_air_detail/other-img-2.png'
import otherImg3 from '../../assets/images/exercise_air_detail/other-img-3.png'
import otherImg4 from '../../assets/images/exercise_air_detail/other-img-4.png'
import Calender from '../../components/calender';
import HealthInfoWrapper from '../../components/health-info-wrapper'
import WaterBg from '../../assets/images/third-set/water-bg.png'
import Footer from '../../components/Footer/Footer';
import FooterBanner from '../../components/footer-banner';

const FusionExerciseWaterDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  var settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    autoplaySpeed: 1500,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }
    ]
  };

  return (
    <div className="fusion-exe-detail-warper">
      <Header />
      <ClusterIcons active />
      <Row>
        <Col sm={12} md={12}>
          <div className="banner-secss">
            <div className="content-sec">
              <h1>Fusion Exercise {'>'} Water</h1>
            </div>
            <img alt="not found" src={bannerIng} />
          </div>
        </Col>
      </Row>
      <HealthInfoWrapper dotGlobal={1} />

      <div className='marginss'>
       <ExerciseDetailVideo bgImg={WaterBg} intensity='maximum'/>
        <Row >
          <Col sm={12} md={12}>
            <div className="sigment-img">
              <img alt="not found" src={nounVideo} className="sigment-img-right" />
              <ExerciseDetailSigmentTab></ExerciseDetailSigmentTab>
            </div>
          </Col>
          <Col sm={12} md={12}>
            <div className="cust-review">
              <h1>Customer Reviews</h1>
              <div className='write-review'>
                <p>Write Review</p>
                <img alt="not found" src={editIcon} title="WRITE REVIEWS" />
              </div>
            </div>
          </Col>
        </Row >
      </div>

      <div className="revie-slider">
        <Slider {...settings}>
          <div className="review-card">
            <div className="card-review">
              <div className="head-sec">
                <img alt="not found" src={pic1} />
                <div className="head-info">
                  <p>Aliquam tellus </p>
                  <h1>Chris Andrews</h1>
                </div>
              </div>
              <div className="star-info">
                <p>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star no-colr"></i>
                </p>
                <p>Home Workout</p>
              </div>
              <div className="info2">
                <img alt="not found" src={startCom} />
                <p>Mauris neque nisi, faucibus non elementum in, convallis et eros. Sed pretium sem libero, vel pellentesque purus ultrices ut. In quis leo id massa pulvinar euismod cursus non justo. </p>
                <img alt="not found" src={endCom} className="img2" />
                <span>26/01/2020</span>
              </div>
            </div>
          </div>
          <div className="review-card">
            <div className="card-review">
              <div className="head-sec">
                <img alt="not found" src={pic2} />
                <div className="head-info">
                  <p>Aliquam tellus </p>
                  <h1>Chris Andrews</h1>
                </div>
              </div>
              <div className="star-info">
                <p>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star no-colr"></i>
                </p>
                <p>Home Workout</p>
              </div>
              <div className="info2">
                <img alt="not found" src={startCom} />
                <p>Mauris neque nisi, faucibus non elementum in, convallis et eros. Sed pretium sem libero, vel pellentesque purus ultrices ut. In quis leo id massa pulvinar euismod cursus non justo. </p>
                <img alt="not found" src={endCom} className="img2" />
                <span>26/01/2020</span>
              </div>
            </div>
          </div>
          <div className="review-card">
            <div className="card-review">
              <div className="head-sec">
                <img alt="not found" src={pic3} />
                <div className="head-info">
                  <p>Aliquam tellus </p>
                  <h1>Chris Andrews</h1>
                </div>
              </div>
              <div className="star-info">
                <p>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star no-colr"></i>
                </p>
                <p>Home Workout</p>
              </div>
              <div className="info2">
                <img alt="not found" src={startCom} />
                <p>Mauris neque nisi, faucibus non elementum in, convallis et eros. Sed pretium sem libero, vel pellentesque purus ultrices ut. In quis leo id massa pulvinar euismod cursus non justo. </p>
                <img alt="not found" src={endCom} className="img2" />
                <span>26/01/2020</span>
              </div>
            </div>
          </div>
          <div className="review-card">
            <div className="card-review">
              <div className="head-sec">
                <img alt="not found" src={pic1} />
                <div className="head-info">
                  <p>Aliquam tellus </p>
                  <h1>Chris Andrews</h1>
                </div>
              </div>
              <div className="star-info">
                <p>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star no-colr"></i>
                </p>
                <p>Home Workout</p>
              </div>
              <div className="info2">
                <img alt="not found" src={startCom} />
                <p>Mauris neque nisi, faucibus non elementum in, convallis et eros. Sed pretium sem libero, vel pellentesque purus ultrices ut. In quis leo id massa pulvinar euismod cursus non justo. </p>
                <img alt="not found" src={endCom} className="img2" />
                <span>26/01/2020</span>
              </div>
            </div>
          </div>
          <div className="review-card">
            <div className="card-review">
              <div className="head-sec">
                <img alt="not found" src={pic1} />
                <div className="head-info">
                  <p>Aliquam tellus </p>
                  <h1>Chris Andrews</h1>
                </div>
              </div>
              <div className="star-info">
                <p>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star no-colr"></i>
                </p>
                <p>Home Workout</p>
              </div>
              <div className="info2">
                <img alt="not found" src={startCom} />
                <p>Mauris neque nisi, faucibus non elementum in, convallis et eros. Sed pretium sem libero, vel pellentesque purus ultrices ut. In quis leo id massa pulvinar euismod cursus non justo. </p>
                <img alt="not found" src={endCom} className="img2" />
                <span>26/01/2020</span>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className='marginss'>
        <Row >
          <Col sm={12} md={12}>
            <div className="exercise-programs">
              <h1>Other exercise programs you may like</h1>
              <div className='view-more'>See all programs</div>
              <Slider {...settings}>
                <div className="exercise-pro">
                  <div className="sig-card">
                    <img alt="not found" src={otherImg1} />
                    <p>Yoga</p>
                  </div>
                </div>
                <div className="exercise-pro">
                  <div className="sig-card">
                    <img alt="not found" src={otherImg2} />
                    <p>Home Workouts</p>
                  </div>
                </div>
                <div className="exercise-pro">
                  <div className="sig-card">
                    <img alt="not found" src={otherImg3} />
                    <p>Dance</p>
                  </div>
                </div>
                <div className="exercise-pro">
                  <div className="sig-card">
                    <img alt="not found" src={otherImg4} />
                    <p>Mind Health Exercise</p>
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
        </Row>
      </div>
      <Calender />
      <FooterBanner />
      <Footer />
      <div id='weight-shift-plan'>Weight Shifter Exercise Plan</div>
    </div>
  );
}

export default FusionExerciseWaterDetail;