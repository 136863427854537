import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import { useLocation } from 'react-router-dom';
import blackDiamond from '../../assets/images/black-diamond.png';
import Yoga from '../../assets/images/fusion-exercise-detail/affirmations-vedio.png';
import Laughing from '../../assets/images/fusion-exercise-detail/laughing.png';
import BreathingImg from '../../assets/images/fusion-exercise-detail/breathingImg.png';
import Yoga2 from '../../assets/images/fusion-exercise-detail/yoga1.png';
import Read from '../../assets/images/fusion-exercise-detail/readimg.png';
import favIcon1 from '../../assets/images/fav_icon1.png';
import favIcon2 from '../../assets/images/fav_icon2.png';
import playerIcons from '../../assets/images/player-icon.png';
import Yoga1 from '../../assets/images/other-img-2.png';
import './ExerciseDetailVideo1.scss';

const ExerciseDetailVideo1 = ({ bgImg = Yoga, laughImg = Laughing, breathingImg = BreathingImg, yoga2 = Yoga2, read = Read, intensity = 'light' }) => {
  const { pathname } = useLocation();
  console.log(pathname)
  const [dotGlobal, changeImg] = useState(1);
  const [intense, setIntensity] = useState(intensity)
  const [bg1, setBg1] = useState(bgImg);
  const [bg2, setBg2] = useState(laughImg);
  const [bg3, setBg3] = useState(breathingImg);
  const [bg5, setBg5] = useState(read);
  const [bg4, setBg4] = useState(yoga2);
  const [bg, setBg] = useState(bgImg);


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    dotsClass: "slick-dots slick-thumb",
    slidesToScroll: 1,
    maxWidth: 50,
    customPaging: function (i) {
      return (
        <a>
          <div className="tb-info">
            <img alt="artifact missing" src={`${'Cluster-'}${i + 1}.png`} onClick={() => changeImg(i + 1)} />
          </div>
        </a>
      );
    },
  };
  var selectData = ['Health Protocol Affirmations',
    'Health Protocol Breathing Exercises & guided meditations',
    'Health Protocol Visualisations',
    'Health Protocol Healing Readings',]
    var selectData1 = [
    'Health Protocol Visualisations',
    'Power Immune System Protocol',
    'Pure Mind Protocol',
    'Pure Blood & Hearty Heart Protocol',
    'Pure Cell Protocol',
    'Strong Bone and Cartilage Protocol',
    'Anti-aging protocol']
  return (
    <>
      <Row>
        <Col sm={12} md={12}>
          <Slider {...settings}>
            <div>
              <div className="vedio-sec" >
                {pathname === '/fusion-exercise-detail1' &&
                  <div className="vedio-con">
                    <div className="vedios">
                      <img alt="artifact missing" src={bg1} />
                      <img alt="artifact missing" src={playerIcons} />
                    </div>
                    <div className="vedios-pagi v-select">
                      <p>On Demand affirmations classes:</p>
                      <div style={{ display: 'flex' }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                          return (
                            <span onClick={() => i !== 1 ? setBg1(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                          )
                        })}
                      </div>
                      <div className='select-sec'>
                        <select className='select'>{
                          selectData.map((x, y) =>
                            <option key={y}>{x}</option>)
                        }
                        </select>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                }

                {pathname === '/fusion-exercise-detail2' &&
                  <div className="vedio-con">
                    <div className="vedios">
                      <img alt="artifact missing" src={bg2} />
                      <img alt="artifact missing" src={playerIcons} />
                    </div>
                    <div className="vedios-pagi v-select">
                      <p>On Demand affirmations classes:</p>
                      <div style={{ display: 'flex' }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                          return (
                            <span onClick={() => i !== 1 ? setBg2(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                          )
                        })}
                      </div>
                      <div className='select-sec'>
                        <select className='select'>{
                          selectData.map((x, y) =>
                            <option key={y}>{x}</option>)
                        }
                        </select>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                }
                {pathname === '/fusion-exercise-detail3' &&
                  <div className="vedio-con">
                    <div className="vedios">
                      <img alt="artifact missing" src={bg3} />
                      <img alt="artifact missing" src={playerIcons} />
                    </div>
                    <div className="vedios-pagi v-select">
                      <p>On Demand Breathing Exercise &<br />
                        guided meditations Classes:</p>
                      <div style={{ display: 'flex' }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                          return (
                            <span onClick={() => i !== 1 ? setBg3(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                          )
                        })}
                      </div>
                      <div className='select-sec'>
                        <select className='select'>{
                          selectData.map((x, y) =>
                            <option selected={x === 'Health Protocol Breathing Exercises & guided meditations'} key={2}>{x}</option>)
                        }
                        </select>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                }
                {pathname === '/fusion-exercise-detail4' &&
                  <div className="vedio-con">
                    <div className="vedios">
                      <img alt="artifact missing" src={bg4} />
                      <img alt="artifact missing" src={playerIcons} />
                    </div>
                    <div className="vedios-pagi v-select">
                      <p>On Demand Breathing Exercise &<br />
                        guided meditations Classes:</p>
                      <div style={{ display: 'flex' }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                          return (
                            <span onClick={() => i !== 1 ? setBg4(i % 2 === 0 ? Yoga1 : Yoga) : setBg4(bgImg)}>{i}</span>
                          )
                        })}
                      </div>
                      <div className='select-sec'>
                        <select className='select'>{
                          selectData.map((x, y) =>
                            <option selected={x === 'Health Protocol Breathing Exercises & guided meditations'} key={2}>{x}</option>)
                        }
                        </select>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                }
                {pathname === '/fusion-exercise-detail5' &&
                  <div className="vedio-con">
                      <h4>Visualisations - Vibrancy</h4>
                    <div className="vedios">
                      <img alt="artifact missing" src={bg2} />
                      <img alt="artifact missing" src={playerIcons} />
                    </div>
                    <div className="vedios-pagi v-select">
                      <p>On Demand visualisations classes:</p>
                      <div style={{ display: 'flex' }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                          return (
                            <span onClick={() => i !== 1 ? setBg2(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                          )
                        })}
                      </div>
                      <div className='select-sec'>
                        <select className='select'>{
                          selectData1.map((x, y) =>
                            <option selected={x === 'Health Protocol Visualisations'} key={4}>{x}</option>)
                        }
                        </select>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                }
                {pathname === '/fusion-exercise-detail6' &&
                  <div className="vedio-con">
                    <div className="vedios">
                      <img alt="artifact missing" src={bg5} />
                      <img alt="artifact missing" src={playerIcons} />
                    </div>
                    <div className="vedios-pagi v-select">
                      <p>On Demand healing readings<br />classes:</p>
                      <div style={{ display: 'flex' }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                          return (
                            <span onClick={() => i !== 1 ? setBg5(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                          )
                        })}
                      </div>
                      <div className='select-sec'>
                        <select className='select'>{
                          selectData.map((x, y) =>
                            <option selected={x === 'Health Protocol Healing Readings'} key={4}>{x}</option>)
                        }
                        </select>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                }
                {pathname === '/fusion-exercise-detail7' &&
                  <div className="vedio-con">
                    <div className="vedios">
                      <img alt="artifact missing" src={bg2} />
                      <img alt="artifact missing" src={playerIcons} />
                    </div>
                    <div className="vedios-pagi v-select">
                      <p>On Demand healing readings <br />
                        classes:</p>
                      <div style={{ display: 'flex' }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                          return (
                            <span onClick={() => i !== 1 ? setBg2(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                          )
                        })}
                      </div>
                      <div className='select-sec'>
                        <select className='select'>{
                          selectData.map((x, y) =>
                          <option selected={x === 'Health Protocol Healing Readings'} key={4}>{x}</option>)
                        }
                        </select>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                }
                {pathname === '/fusion-exercise-detail8' &&
                  <div className="vedio-con">
                    <div className="vedios">
                      <img alt="artifact missing" src={bg} />
                      <img alt="artifact missing" src={playerIcons} />
                    </div>
                    <div className="vedios-pagi v-select">
                      <p>On Demand affirmations classes:</p>
                      <div style={{ display: 'flex' }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                          return (
                            <span onClick={() => i !== 1 ? setBg(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                          )
                        })}
                      </div>
                      <div className='select-sec'>
                        <select className='select'>{
                          selectData.map((x, y) =>
                            <option key={y}>{x}</option>)
                        }
                        </select>
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                }
                <div className="profile-info">
                  <div className="info-con">
                    <div className="profile-img">
                      <img alt="artifact missing" src={blackDiamond} />
                      <h3>Exp: 35 years</h3>
                    </div>
                    <div className="profile-text">
                      <label>Trainer :</label><span>John Doe</span>
                      <p>Kim Larking, creator of Mind X Body Fusion Exercise, author of the book Health Psychology, 6th degree Kiaido Ryu black belt, aikido and yoga enthusiast, anti-aging and health span educator.</p>
                    </div>
                  </div>
                  <div className="profile-wrap">
                    <Row>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>My Health Info</h2>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Age:</p>
                          <p>18</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Gender:</p>
                          <p>Female</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Weight:</p>
                          <p>54kg</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Height:</p>
                          <p>168cm</p>
                        </div>

                      </Col>
                      <div className="text-cmp">
                        <p> >> Complete Your Health Info</p>
                      </div>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>Workout Info</h2>
                          <div className="shealth">
                            <p>Workout Duration:</p>
                            <span>60 Minutes</span>
                          </div>
                        </div>
                        <div className="work-health">
                          <p>Intent intensity:</p>
                          <div className="btn-health">
                            <button className={`${intense === 'light' ? 'hactive' : ''}`} onClick={() => setIntensity('light')}>Light</button>
                            <button className={`${intense === 'medium' ? 'hactive' : ''}`} onClick={() => setIntensity('medium')}>Medium</button>
                            <button className={`${intense === 'maximum' ? 'hactive' : ''}`} onClick={() => setIntensity('maximum')}>Maximum</button>
                          </div>
                        </div>
                        <div className="max-health">
                          <p>Maximum Expected Calorie Burn:</p>
                          <p>300kcl</p>
                          <img alt="artifact missing" src={favIcon1} />
                          <img alt="artifact missing" src={favIcon2} />
                        </div>
                        <div className="max-health mt-2">
                          <a href="">Pre Workout Life Force Food</a>
                          <a href="">Post Work Life Force Food</a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="vedio-sec">
                <div className="vedio-con">
                  <div className="vedios">
                    <img alt="artifact missing" src={Yoga1} />
                    <img alt="artifact missing" src={playerIcons} />
                  </div>
                  <div className="vedios-pagi">
                    <p>On Demand air classes:</p>
                    <div style={{ display: 'flex' }}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                        return (
                          <span onClick={() => i !== 1 ? setBg(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="profile-info">
                  <div className="info-con">
                    <div className="profile-img">
                      <img alt="artifact missing" src={blackDiamond} />
                      <h3>Exp: 35 years</h3>
                    </div>
                    <div className="profile-text">
                      <label>Trainer :</label><span>John</span>
                      <p>Kim Larking, creator of Mind X Body Fusion Exercise, author of the book Health Psychology, 6th degree Kiaido Ryu black belt, aikido and yoga enthusiast, anti-aging and health span educator.</p>
                    </div>
                  </div>
                  <div className="profile-wrap">
                    <Row>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>My Health Info</h2>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Age:</p>
                          <p>18</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Gender:</p>
                          <p>Female</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Weight:</p>
                          <p>54kg</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Height:</p>
                          <p>168cm</p>
                        </div>

                      </Col>
                      <div className="text-cmp">
                        <p> >> Complete Your Health Info</p>
                      </div>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>Workout Info</h2>
                          <div className="shealth">
                            <p>Workout Duration:</p>
                            <span>60 Minutes</span>
                          </div>
                        </div>
                        <div className="work-health">
                          <p>Intent intensity:</p>
                          <div className="btn-health">
                            <button className={`${intense === 'light' ? 'hactive' : ''}`} onClick={() => setIntensity('light')}>Light</button>
                            <button className={`${intense === 'medium' ? 'hactive' : ''}`} onClick={() => setIntensity('medium')}>Medium</button>
                            <button className={`${intense === 'maximum' ? 'hactive' : ''}`} onClick={() => setIntensity('maximum')}>Maximum</button>
                          </div>
                        </div>
                        <div className="max-health">
                          <p>Maximum Expected Calorie Burn:</p>
                          <p>300kcl</p>
                          <img alt="artifact missing" src={favIcon1} />
                          <img alt="artifact missing" src={favIcon2} />
                        </div>
                        <div className="max-health">
                          <a href="">Pre Workout Life Force Food</a>
                          <a href="">Post Work Life Force Food</a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="vedio-sec">
                <div className="vedio-con">
                  <div className="vedios">
                    <img alt="artifact missing" src={Yoga} />
                    <img alt="artifact missing" src={playerIcons} />
                  </div>
                  <div className="vedios-pagi">
                    <p>On Demand air classes:</p>
                    <div style={{ display: 'flex' }}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                        return (
                          <span onClick={() => i !== 1 ? setBg(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="profile-info">
                  <div className="info-con">
                    <div className="profile-img">
                      <img alt="artifact missing" src={blackDiamond} />
                      <h3>Exp: 35 years</h3>
                    </div>
                    <div className="profile-text">
                      <label>Trainer :</label><span>Kim Larking</span>
                      <p>Kim Larking, creator of Mind X Body Fusion Exercise, author of the book Health Psychology, 6th degree Kiaido Ryu black belt, aikido and yoga enthusiast, anti-aging and health span educator.</p>
                    </div>
                  </div>
                  <div className="profile-wrap">
                    <Row>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>My Health Info</h2>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Age:</p>
                          <p>18</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Gender:</p>
                          <p>Female</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Weight:</p>
                          <p>54kg</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Height:</p>
                          <p>168cm</p>
                        </div>

                      </Col>
                      <div className="text-cmp">
                        <p> >> Complete Your Health Info</p>
                      </div>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>Workout Info</h2>
                          <div className="shealth">
                            <p>Workout Duration:</p>
                            <span>60 Minutes</span>
                          </div>
                        </div>
                        <div className="work-health">
                          <p>Intent intensity:</p>
                          <div className="btn-health">
                            <button className={`${intense === 'light' ? 'hactive' : ''}`} onClick={() => setIntensity('light')}>Light</button>
                            <button className={`${intense === 'medium' ? 'hactive' : ''}`} onClick={() => setIntensity('medium')}>Medium</button>
                            <button className={`${intense === 'maximum' ? 'hactive' : ''}`} onClick={() => setIntensity('maximum')}>Maximum</button>
                          </div>
                        </div>
                        <div className="max-health">
                          <p>Maximum Expected Calorie Burn:</p>
                          <p>300kcl</p>
                          <img alt="artifact missing" src={favIcon1} />
                          <img alt="artifact missing" src={favIcon2} />
                        </div>
                        <div className="max-health">
                          <a href="">Pre Workout Life Force Food</a>
                          <a href="">Post Work Life Force Food</a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="vedio-sec">
                <div className="vedio-con">
                  <div className="vedios">
                    <img alt="artifact missing" src={Yoga1} />
                    <img alt="artifact missing" src={playerIcons} />
                  </div>
                  <div className="vedios-pagi">
                    <p>On Demand air classes:</p>
                    <div style={{ display: 'flex' }}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
                        return (
                          <span onClick={() => i !== 1 ? setBg(i % 2 === 0 ? Yoga1 : Yoga) : setBg(bgImg)}>{i}</span>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="profile-info">
                  <div className="info-con">
                    <div className="profile-img">
                      <img alt="artifact missing" src={blackDiamond} />
                      <h3>Exp: 35 years</h3>
                    </div>
                    <div className="profile-text">
                      <label>Trainer :</label><span>Larking</span>
                      <p>Kim Larking, creator of Mind X Body Fusion Exercise, author of the book Health Psychology, 6th degree Kiaido Ryu black belt, aikido and yoga enthusiast, anti-aging and health span educator.</p>
                    </div>
                  </div>
                  <div className="profile-wrap">
                    <Row>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>My Health Info</h2>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Age:</p>
                          <p>18</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Gender:</p>
                          <p>Female</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Weight:</p>
                          <p>54kg</p>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="health">
                          <p>Height:</p>
                          <p>168cm</p>
                        </div>

                      </Col>
                      <div className="text-cmp">
                        <p> >> Complete Your Health Info</p>
                      </div>
                      <Col sm={12} md={12}>
                        <div className="profile-health">
                          <h2>Workout Info</h2>
                          <div className="shealth">
                            <p>Workout Duration:</p>
                            <span>60 Minutes</span>
                          </div>
                        </div>
                        <div className="work-health">
                          <p>Intent intensity:</p>
                          <div className="btn-health">
                            <button className={`${intense === 'light' ? 'hactive' : ''}`} onClick={() => setIntensity('light')}>Light</button>
                            <button className={`${intense === 'medium' ? 'hactive' : ''}`} onClick={() => setIntensity('medium')}>Medium</button>
                            <button className={`${intense === 'maximum' ? 'hactive' : ''}`} onClick={() => setIntensity('maximum')}>Maximum</button>
                          </div>
                        </div>
                        <div className="max-health">
                          <p>Maximum Expected Calorie Burn:</p>
                          <p>300kcl</p>
                          <img alt="artifact missing" src={favIcon1} />
                          <img alt="artifact missing" src={favIcon2} />
                        </div>
                        <div className="max-health">
                          <a href="">Pre Workout Life Force Food</a>
                          <a href="">Post Work Life Force Food</a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </Col>
      </Row >
    </>
  );
}

export default ExerciseDetailVideo1;